<template>
  <div style="height: 100%;">
    <v-row
      v-if="currentStep<2"
      no-gutters
    >
      <v-col>
        <base-stepper
          v-model="currentStep"
          :items="stepList"
          @close="$router.back()"
        >
          <v-row
            justify="center"
            no-gutters
          >
            <v-tabs-items
              v-model="currentStep"
              style="width:100%"
            >
              <v-tab-item
                :value="0"
                eager
              >
                <step-file
                  v-model="excelData"
                  @continue="currentStep=1"
                />
              </v-tab-item>
              <v-tab-item :value="1">
                <step-table
                  v-if="currentStep==1"
                  :excel-data="excelData"
                  @continue="onSuccessImport"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-row>
        </base-stepper>
      </v-col>
    </v-row>
    <base-empty-block-page
      v-else
      title="Импорт завершен"
      action-text="Закрыть"
      action
      @action="$router.back()"
    >
      <template v-slot:description>
        <v-row
          v-if="importResult"
          no-gutters
        >
          <v-col>
            <v-row>
              <v-col cols="auto">
                Категории:
              </v-col>
              <v-col cols="auto">
                Создано:{{ importResult.categories.filter(item=>item.sync_result === 'insert' ).length }}
              </v-col>
              <v-col cols="auto">
                Обновлено:{{ importResult.categories.filter(item=>item.sync_result === 'update' || item.sync_result === 'none' ).length }}
              </v-col>
              <!-- <v-row><v-col>Удалено:{{importResult.categories.filter(item=>item.sync_result === 'inserted' )}}</v-col></v-row> -->
            </v-row>
            <v-row>
              <v-col cols="auto">
                Номенклатура:
              </v-col>
              <v-col cols="auto">
                Создано:{{ importResult.nomenclatures.filter(item=>item.sync_result === 'insert' ).length }}
              </v-col>
              <v-col cols="auto">
                Обновлено:{{ importResult.nomenclatures.filter(item=>item.sync_result === 'update' || item.sync_result === 'none' ).length }}
              </v-col>
              <!-- <v-row><v-col>Удалено:{{importResult.categories.filter(item=>item.sync_result === 'inserted' )}}</v-col></v-row> -->
            </v-row>
          </v-col>
        </v-row>
      </template>
    </base-empty-block-page>
  </div>
</template>

<script>

  import StepFile from './Step1File'
  import StepTable from './Step2Table'

  export default {
    components: { StepFile, StepTable },
    constants: {
      stepList: [
        { title: 'Выбор файла' },
        { title: 'Импорт данных' },
      ],
    },
    data () {
      return {
        currentStep: 0,
        excelData: [],
        importResult: null,
      }
    },
    computed: {

    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.currentStep = 0
      },
      onSuccessImport (result) {
        console.log(result)
        this.importResult = result
        this.currentStep = 2
      },

    },
  }
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';

</style>
