<template lang="">
  <v-container class="pa-0">
    <!-- <v-row>{{ excelData }}</v-row> -->
    <BaseMasterFieldBlock
      title="Настройки импорта"
    >
      <span
        slot="description"
      >Выберите в колонках таблице к какому полю отнести импортируемые данные.</span>
      <template v-slot:input>
        <v-row>
          <v-col>
            <v-row
              justify="start"
              align="center"
            >
              <v-col
                cols="auto"
                class="body-l-semibold"
              >
                Предварительно загружено: {{ excelData.length }} {{ declOfNum(excelData.length, rowStr) }}
              </v-col>
              <v-col
                v-if="errors.length"
                cols="auto"
              >
                <v-btn
                  color="error"
                  @click="showErrors('error')"
                >
                  <v-icon left>
                    $iconify_bx-bxs-error-alt
                  </v-icon> <span
                    class="my-stepper-import--form-status-error-text"
                  >{{ errors.length }} {{ declOfNum(errors.length, errorStr) }}
                  </span>
                </v-btn>
              </v-col>
              <v-col
                v-if="warnings.length"
                cols="auto"
              >
                <v-btn
                  color="warning"
                  @click="showErrors('warning')"
                >
                  <v-icon left>
                    $iconify_bx-bxs-error
                  </v-icon> <span
                    class="my-stepper-import--form-status-error-text"
                  >{{ warnings.length }} {{ declOfNum(warnings.length, warningStr) }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        v-for="(item, i) in headers"
                        :key="`header-${i}`"
                        class="text-left"
                      >
                        <div>{{ item === '__rowNum__' ? '№' : item }}</div>
                        <div class="my-stepper-import--step-two-select">
                          <v-select
                            v-if="item !== '__rowNum__'"
                            v-model="cols[i]"
                            :items="types"
                            placeholder="Выберите тип ячейки"
                            class="body-m-regular"
                            outlined
                            hide-details
                            clearable
                            dense
                            @change="syncCols(i)"
                          />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row, i) in rows"
                      :key="`row-${i}`"
                    >
                      <td
                        v-for="(cell, j) in row"
                        :key="`cell-${i}${j}`"
                      >
                        {{ cell }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </BaseMasterFieldBlock>

    <v-row>
      <v-col>
        <v-btn
          :disabled="!isValidExcel || importAction"
          :loading="importAction"
          color="primary"
          @click="onImportClick"
        >
          Импорт
        </v-btn>
      </v-col>
    </v-row>
    <v-overlay
      absolute
      :value="importAction"
    >
      <v-row justify="center">
        <v-col cols="auto">
          <v-progress-circular
            indeterminate
            size="64"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-progress-linear
            :height="25"
            style="width:100px"
            :value="progress"
            color="success"
          >
            <template v-slot="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            @click="importAction=false"
          >
            Отмена
          </v-btn>
        </v-col>
      </v-row>
    </v-overlay>
  </v-container>
</template>

<script>
  import convertor from '@/mixins/convertor'
  import { validUUID } from '@/utils/validate'
  import { mapActions } from 'vuex'

  export default {
    components: {

    },
    mixins: [convertor],
    props: {
      excelData: {
        type: Array,
        required: true,
      },
    },
    data () {
      return {
        importAction: false,
        chunkSize: 100,
        progress: 0,
        maxAsyncTask: 2,
        maxCol: 8,
        types: [
          { value: 'uuid', text: 'UUID', required: false },
          { value: 'external_id', text: 'Внешний ID', required: false },
          { value: 'name', text: 'Наименование', required: true },
          { value: 'description', text: 'Описание', required: false },
          { value: 'category_uuid', text: 'UUID категории', required: false },
          { value: 'category_name', text: 'Категория', required: false },
        ],
        cols: [], // колонки excel по типам
        errors: [], // ошибки
        warnings: [], // предупреждения
        viewErr: [], // просмотр ошибок
        viewType: null,
        rowStr: ['строка', 'строки', 'строк'],
        errorStr: ['ошибка', 'ошибки', 'ошибок'],
        warningStr: ['предупреждение', 'предупреждения', 'предупреждений'],

      }
    },

    computed: {
      program () {
        return this.$store.getters['company/program/program']
      },
      headers () {
        if (this.excelData.length) {
          return Object.keys(this.excelData[0])
        }
        return []
      },
      rows () {
        if (this.excelData.length) {
          return this.excelData.slice(0, 5)
        }
        return []
      },
      reqCount () {
        let reqCount = 0
        const req = this.types.filter(item => item.required)
        if (req.length) {
          req.forEach(item => {
            if (this.cols.indexOf(item.value) !== -1) {
              reqCount++
            }
          })
        }
        return reqCount
      },
      isValidExcel () {
        if (this.reqCount && !this.errors.length) {
          return true
        }
        return false
      },
    },
    watch: {

    },
    mounted () {

    },
    beforeDestroy () {
      this.importAction = false
    },
    methods: {
      ...mapActions({
        SycnArrayNomenclature: 'company/nomenclature/SycnArrayNomenclature',
      }),
      validate () {
        let errors = []
        let warnings = []

        this.cols.forEach((type, i) => {
          if (type) {
            switch (type) {
              case 'uuid':
              case 'name':
              case 'category_uuid':
                // errors.concat(this.checkingErrors(type, i))
                errors = errors.concat(this.checkingErrors(type, i))
                break
              default:
                warnings = warnings.concat(this.checkingWarnings(type, i))
                break
            }
          }
        })
        this.errors = errors
        this.warnings = warnings
        return this.isValidExcel
      },

      // выбор колонок
      syncCols (i) {
        const value = this.cols[i]
        this.cols.forEach((item, index) => {
          if (item === value && i !== index) {
            this.cols[index] = null
          }
        })
        this.validate()
      },
      checkingErrors (type, i) {
        const errors = []
        this.excelData.forEach((row) => {
          const col = Object.keys(row)[i]
          const rowNum = row.__rowNum__
          const cell = row[col]
          switch (type) {
            case 'uuid':
              if (!cell) errors.push(`Строка ${(rowNum)}, колонка "${col}": Отсутствует UUID`)
              if (cell && !validUUID(cell)) errors.push(`Строка ${(rowNum)}, колонка "${col}": UUID неверного формата`)
              break
            case 'category_uuid':
              if (!cell) errors.push(`Строка ${(rowNum)}, колонка "${col}": Отсутствует UUID категории `)
              if (cell && !validUUID(cell)) errors.push(`Строка ${(rowNum)}, колонка "${col}": UUID неверного формата`)
              break
            case 'name':
              if (!cell) errors.push(`Строка ${(rowNum)}, колонка "${col}": Отсутствует наименование`)
              break
          }
        })
        return errors
      },
      checkingWarnings (type, i) {
        const warnings = []
        // this.excelData.forEach((row) => {
        //   const col = Object.keys(row)[i]
        //   // const rowNum = row.__rowNum__
        //   // const cell = row[col]
        //   if (!cell) warnings.push(`Строка ${(rowNum)}, колонка "${col}": Пустая ячейка`)
        // })
        return warnings
      },
      showErrors (type) {
        let message = ''
        let title = ''

        if (type === 'error') {
          title = 'Ошибки'
          message = this.errors.join('<br/>')
        }
        if (type === 'warning') {
          title = 'Предупреждения'
          message = this.warnings.join(', ')
        }

        this.$alert(message, title, { type: type })
      },

      // подготовка excel
      getSelectedExcel () {
        const toUpload = []
        const excelMask = []
        this.cols.forEach((item, index) => {
          if (item) {
            excelMask.push({
              item,
              index,
            })
          }
        })
        this.excelData.forEach(row => {
          const newRow = {}
          newRow.__rowNum__ = row.__rowNum__
          excelMask.forEach(mask => {
            let i = 0
            let cell = null
            for (const j in row) {
              if (i === mask.index) {
                cell = j
                break
              }
              i++
            }
            if (cell) {
              newRow[mask.item] = row[cell]
            }
          })
          toUpload.push(newRow)
        })
        console.log('toUpload')
        console.log(toUpload)
        return Object.copy(toUpload)
      },
      async onImportClick () {
        if (this.importAction || !this.validate()) return

        try {
          this.progress = 0
          this.importAction = true
          const selectedExcel = this.getSelectedExcel() // подготовка excel
          const selectedExcelChunked = this.$_.chunk(selectedExcel, this.chunkSize)
          const resList = []
          const tasks = selectedExcelChunked.map((pack) => {
            return async () => {
              if (!this.importAction) throw new Error('task stopped')
              const postData = {
                program_id: this.program.id,
                nomenclatures: pack,
              }
              console.log(postData)

              const res = await this.SycnArrayNomenclature(postData)
              resList.push(res)
              this.progress += Math.round(100 / selectedExcelChunked.length)
            }
          })

          console.log('make task')

          // sync process
          if (true) {
            for (const task of tasks) {
              await task()
            }
          } else {
            // all async
            await Promise.all(tasks)
          }

          const resTotal = { categories: [], nomenclatures: [] }
          resList.forEach(res => {
            resTotal.categories = resTotal.categories.concat(res.categories)
            resTotal.nomenclatures = resTotal.nomenclatures.concat(res.nomenclatures)
          })
          await this.$sleep(100)
          this.$emit('continue', resTotal)
        } catch (e) {
          console.error(e)
        } finally {
          this.importAction = false
        }
      },
    },

  }
</script>
