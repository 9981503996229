<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col cols="8">
        <v-form
          ref="form"
          v-model="formValid"
        >
          <BaseMasterFieldBlock
            title="Загрузите файл"
          >
            <span
              slot="description"
            > <v-btn
              color="primary"
              class="v-btn--link"
              text              
             :href="'/storage/Пример-Plus.xls'"
            >Пример Excel-файла</v-btn> </span>
            <template v-slot:input>
              <input
                v-show="false"
                ref="uploadInput"
                type="file"
                accept=".xlsx, .xls, .csv"
                @change="handleUpload"
              >

              <v-row
                justify="center"
                class="upload-area"
                @drop.stop.prevent="handleDrop"
                @dragover.stop.prevent="handleDragover"
                @dragenter.stop.prevent="handleDragover"
                @click="uploadBtnClick"
              >
                <v-col>
                  <v-row justify="center">
                    <v-col cols="auto">
                      <v-icon
                        style="width: 72px;height: 72px;"
                        width="72"
                      >
                        $iconify_clarity-import-line
                      </v-icon>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="auto">
                      Допустимые форматы файла: xlsx, xls, csv
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="auto">
                      <v-btn
                        color="primary"
                      >
                        Загрузить
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </BaseMasterFieldBlock>

          <v-row v-if="false">
            <v-col>
              <v-btn
                :disabled="!valid"
                color="primary"
                class="master-next-btn"
                @click="onNextClick"
              >
                Далее
                <v-icon right>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import XLSX from 'xlsx'

  export default {
    components: {

    },
    model: {
      prop: 'excelData',
      event: 'change',
    },
    props: {
      excelData: {
        type: Array,
        default: () => ([]),
      },
    },
    data () {
      return {
        loading: false,
        formValid: false,
        excelBuf: [],
        files: [],

      }
    },
    computed: {
      valid () {
        return this.formValid
      },
    },
    created () {

    },
    methods: {
      // downloadExampleFile () {
      //   const url = require('@/assets/excel/Пример-Plus.xls')

      // },
      uploadBtnClick () {
        console.log('uploadBtnClick', this.$refs.uploadInput)
        this.$refs.uploadInput.click()
      },
      handleDragover (e) {
        e.dataTransfer.dropEffect = 'move'
      },
      handleDrop (e) {
        this.validateUpload(e.dataTransfer.files)
      },
      handleUpload (e) {
        console.log('handleUpload', e)
        this.validateUpload(e.target.files)
      },
      clearFiles () {
        this.$refs.uploadInput.value = null
      },
      async validateUpload (files) {
        try {
          this.loading = true

          if (files.length > 1) {
            return this.$notify({
              type: 'error',
              title: 'Импорт клиентов',
              text: 'Загружайте файлы по одному',
            })
          }
          const file = files[0]
          // let isExcel = /\.(xlsx|xls|csv)$/.test(file.name)
          const type = this.getType(file.name)
          // тип и размер файла
          if (!type) {
            return this.$notify({
              type: 'error',
              title: 'Импорт клиентов',
              text: 'Неверный тип файла',
            })
          }
          if (file.size > 10240000) {
            return this.$notify({
              type: 'error',
              title: 'Импорт клиентов',
              text: 'Размер файла более 10 МБ',
            })
          }
          // очищаем input
          this.clearFiles()
          // парсинг excel
          await this.readerData(file, type)
          // максимальный размер загружаемых строк
          if (this.excelBuf.length > 10000) {
            return this.$notify({
              type: 'error',
              title: 'Импорт клиентов',
              text: 'Объем данных более 10000 строк',
            })
          }
          this.$emit('change', this.excelBuf)
          this.$emit('continue', true)
        } finally {
          this.loading = false
        }
      },
      getType (name) {
        if (name.indexOf('.xlsx') !== -1) return 'xlsx'
        if (name.indexOf('.xls') !== -1) return 'xls'
        if (name.indexOf('.csv') !== -1) return 'csv'
        return false
      },
      readerData (rawFile, fileType) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = e => {
            const data = e.target.result
            // console.log(data);
            const workBook = XLSX.read(data, {
              type: fileType === 'csv' ? 'string' : 'binary',
              cellText: false,
              cellDates: true,
            })
            // console.log(workBook);
            const firstSheetName = workBook.SheetNames[0]
            // console.log(firstSheetName);
            const workSheet = workBook.Sheets[firstSheetName]
            // console.log(workSheet);
            // const headers = this.getHeaderRow(workSheet);
            // console.log(header);
            // const results = XLSX.utils.sheet_to_json(workSheet, { header: this.headers })
            const results = XLSX.utils.sheet_to_json(workSheet, {
              raw: false,
              dateNF: 'YYYY"-"MM"-"DD',
              // dateNF: 'YYYY"-"MM"-"DD" "HH":"mm":"ss',
              defval: '',
            })
            // console.log(results)
            this.excelBuf = []
            // подготовка результаов
            results.forEach(item => {
              const row = {}
              for (const key in item) {
                // номер строки
                row.__rowNum__ = item.__rowNum__
                // убираем пустые ячейки
                if (key.indexOf('EMPTY') === -1) {
                  row[key] = item[key]
                }
              }
              this.excelBuf.push(row)
            })
            resolve()
          }
          reader.readAsBinaryString(rawFile)
        })
      },
      getHeaderRow (sheet) {
        const headers = []
        const range = XLSX.utils.decode_range(sheet['!ref'])
        let C
        const R = range.s.r
        /* start in the first row */
        for (C = range.s.c; C <= range.e.c; ++C) {
          /* walk every column in the range */
          const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]
          /* find the cell in the first row */
          let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
          if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
          headers.push(hdr)
        }
        return headers
      },
      onNextClick () {
        if (this.$refs.form.validate()) {
          this.$emit('change', this.excelBuf)
          this.$emit('continue', true)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
@import 'master-style.scss';
@import '@/views/dashboard/form_component/_form-component.scss';

.upload-area {
  border-radius: 5px;
    border: 1px dashed #cfd8dc;
    cursor: pointer;
}
</style>
